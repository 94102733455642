<template>
  <div>
    <h3>Kurs Statistiken pro Monat</h3>
    <p>
      <label for="month">Monat auswählen</label>
      <input
        type="month"
        name="month"
        v-model="selectedMonth"
        class="form-control"
      />
    </p>
    <p>
      <button
        type="button"
        class="btn btn-dark"
        @click="createPerMonth"
        :disabled="loading"
      >
        Download
      </button>
    </p>
    <h3>Kurs Statistiken pro Jahr</h3>
    <p>
      <label for="year">Jahr auswählen</label>
      <input
        type="year"
        name="year"
        v-model="selectedYear"
        class="form-control"
      />
    </p>
    <p>
      <button
        type="button"
        class="btn btn-dark"
        @click="createPerYear"
        :disabled="loading"
      >
        Download
      </button>
    </p>
    <h3>Kurs Statistiken pro Studio</h3>
    <p>
      <label for="studio">Studio auswählen</label>
      <select name="studio" v-model="selectedStudio" class="form-control">
        <option :value="null" disabled>Studio auswählen...</option>
        <option :value="studio" v-for="studio in studios" :key="studio.id">{{
          studio.name
        }}</option>
      </select>
    </p>
    <p>
      <label for="course">Kurs auswählen</label>
      <select name="course" v-model="selectedCourse" class="form-control">
        <option :value="null">Keine Kurs ausgewählt</option>
        <option
          :value="course"
          v-for="course in coursesPerStudio"
          :key="course.id"
          >{{ course.name }}</option
        >
      </select>
    </p>
    <p>
      <label for="year">Jahr auswählen</label>
      <input
        type="year"
        name="year"
        v-model="selectedYearPerStudio"
        class="form-control"
      />
    </p>
    <p>
      <label for="month">Monat auswählen</label>
      <input
        type="month"
        name="month"
        v-model="selectedMonthPerStudio"
        class="form-control"
      />
    </p>
    <p>
      <button
        type="button"
        class="btn btn-dark"
        @click="createPerStudio"
        :disabled="loading || !selectedStudio"
      >
        Download
      </button>
    </p>
    <h3>Alle Einträge herunterladen</h3>
    <p>
      <button
        type="button"
        class="btn btn-dark"
        @click="createAll"
        :disabled="loading"
      >
        Download
      </button>
    </p>
  </div>
</template>

<script>
import { groupBy } from 'lodash';
import { format, addMonths, addYears, subHours } from 'date-fns';

import { firestore } from '../firebase';

export default {
  data() {
    return {
      loading: false,
      studios: [],
      courses: [],
      selectedMonth: format(new Date(), 'yyyy-MM'),
      selectedMonthPerStudio: '',
      selectedYearPerStudio: '',
      selectedYear: format(new Date(), 'yyyy'),
      selectedStudio: null,
      selectedCourse: null
    };
  },
  async created() {
    const studiosSnapshot = await firestore.collection('studios').get();

    this.studios = studiosSnapshot.docs.map(doc => {
      return {
        id: doc.id,
        ...doc.data()
      };
    });

    const coursesSnapshot = await firestore.collection('courses').get();

    this.courses = coursesSnapshot.docs.map(doc => {
      return {
        id: doc.id,
        ...doc.data()
      };
    });
  },
  computed: {
    coursesPerStudio() {
      return this.courses.filter(c => c.studio === this.selectedStudio?.id);
    }
  },
  methods: {
    createCSV(snapshot) {
      const records = snapshot.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        };
      });

      const result = {};

      const groupedCoursesByName = groupBy(records, 'course.name');
      const groupedStudiosByName = groupBy(records, 'studio.name');

      const headers = [
        'Kursform',
        'Anzahl Kurse',
        'Teilnehmerzahl Gesamt',
        ...Object.keys(groupedStudiosByName)
      ];

      Object.keys(groupedCoursesByName).forEach(key => {
        result[key] = {
          sum: groupedCoursesByName[key].length,
          participants: groupedCoursesByName[key].reduce((prev, curr) => {
            return prev + curr.participants;
          }, 0)
        };

        const groupedStudiosById = groupBy(
          groupedCoursesByName[key],
          'studio.id'
        );

        Object.keys(groupedStudiosById).forEach(key2 => {
          const name = groupedStudiosById[key2][0].studio.name;
          result[key][name] = groupedStudiosById[key2].reduce((prev, curr) => {
            return prev + curr.participants;
          }, 0);
        });
      });

      let csv = '\uFEFF';

      csv += headers.join(';');
      csv += '\n';

      Object.keys(result).forEach(key => {
        const values = [];

        values.push(key);
        values.push(result[key].sum);
        values.push(result[key].participants);

        Object.keys(groupedStudiosByName).forEach(sKey => {
          let notFound = true;
          Object.keys(result[key]).forEach(rKey => {
            if (rKey !== 'sum' && rKey !== 'participants') {
              if (sKey === rKey) {
                notFound = false;
                values.push(result[key][rKey]);
              }
            }
          });

          if (notFound) {
            values.push(0);
          }
        });

        csv += values.join(';');
        csv += '\n';
      });

      return csv;
    },
    async createPerYear() {
      const snapshot = await firestore
        .collection('courseTimeRecords')
        .where('timestamp', '>=', new Date(this.selectedYear).getTime())
        .where(
          'timestamp',
          '<=',
          new Date(addYears(new Date(this.selectedYear), 1)).getTime()
        )
        .get();

      const csv = this.createCSV(snapshot);

      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = `statistics_all_per_year_${this.selectedYear}.csv`;
      hiddenElement.click();
    },
    async createPerMonth() {
      const snapshot = await firestore
        .collection('courseTimeRecords')
        .where('timestamp', '>=', new Date(this.selectedMonth).getTime())
        .where(
          'timestamp',
          '<=',
          new Date(addMonths(new Date(this.selectedMonth), 1)).getTime()
        )
        .get();

      const csv = this.createCSV(snapshot);

      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = `statistics_all_per_month_${this.selectedMonth}.csv`;
      hiddenElement.click();
    },

    async createPerStudio() {
      let snapshot;

      let query = firestore
        .collection('courseTimeRecords')
        .where('studio.id', '==', this.selectedStudio.id);

      if (this.selectedCourse) {
        query = query.where('course.id', '==', this.selectedCourse.id);
      }

      if (this.selectedYearPerStudio && !this.selectedMonthPerStudio) {
        query = query
          .where(
            'timestamp',
            '>=',
            new Date(this.selectedYearPerStudio).getTime()
          )
          .where(
            'timestamp',
            '<=',
            new Date(
              addYears(new Date(this.selectedYearPerStudio).getTime(), 1)
            ).getTime()
          );
      } else if (this.selectedMonthPerStudio) {
        query = query
          .where(
            'timestamp',
            '>=',
            new Date(this.selectedMonthPerStudio).getTime()
          )
          .where(
            'timestamp',
            '<=',
            addMonths(
              subHours(new Date(this.selectedMonthPerStudio).getTime(), 1),
              1
            ).getTime()
          );
      }

      snapshot = await query.orderBy('timestamp').get();

      const records = snapshot.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        };
      });

      const csv = this.createAllCSV(records);

      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = `statistics_${this.selectedStudio.name}.csv`;
      hiddenElement.click();
    },
    async createAll() {
      if (
        confirm(
          'Dieser Vorgang dauert eine Weile, bist du sicher, dass du ALLE Einträge exportieren willst?'
        )
      ) {
        try {
          this.loading = true;

          const snapshot = await firestore
            .collection('courseTimeRecords')
            .orderBy('timestamp')
            .get();

          const courseTimeRecords = snapshot.docs.map(doc => {
            return {
              id: doc.id,
              ...doc.data()
            };
          });

          const csv = this.createAllCSV(courseTimeRecords);

          const hiddenElement = document.createElement('a');
          hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
          hiddenElement.target = '_blank';
          hiddenElement.download = `statistics_all.csv`;
          hiddenElement.click();
        } catch (e) {
          alert(e.message);
        } finally {
          this.loading = false;
        }
      }
    },
    createAllCSV(data) {
      const headers = [
        'Studio',
        'Trainer',
        'Kurs',
        'Datum',
        'Startzeit',
        'Endzeit',
        'Dauer',
        'Teilnehmeranzahl',
        'GmbH'
      ];

      let csv = '\uFEFF';

      csv += headers.join(';');
      csv += '\n';

      data.forEach(record => {
        const values = [];

        values.push(record.studio.name);
        values.push(record.trainer.displayName);
        values.push(record.course.name);

        if (record.timestamp) {
          values.push(format(new Date(record.timestamp), 'dd/MM/yyyy'));
        } else {
          values.push(format(new Date(record.date), 'dd/MM/yyyy'));
        }

        values.push(record.course.starts);
        values.push(record.course.ends);
        values.push(record.time);
        values.push(record.participants);
        values.push(record.studio.holding);

        csv += values.join(';');
        csv += '\n';
      });

      return csv;
    }
  }
};
</script>
